import React from 'react'
import "./Report.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import {connect} from 'react-redux'
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as FileSaver from 'file-saver';
import { Button, TextField,Autocomplete } from '@mui/material';
function Report(props) {
    const [display,setDisplay]=React.useState(false)
    const [data,setData]=React.useState([])
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedDate2, setSelectedDate2] = React.useState("");
    const options = ['Option 1', 'Option 2'];
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [allMerchants,setAllMerchants] = React.useState([{name:"First Entry"}])
    const [checked, setChecked] = React.useState(true);
    const [merchantId,setMerchantId] = React.useState("")
    const [error,setError] = React.useState("")

    const [allOrg,setAllOrg] = React.useState([])
    const [selectedOrg,setSelectedOrg] = React.useState(null)

    const [allSurvey,setAllSurvey]=React.useState([])
    React.useEffect(()=>{
        // axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/org/single`,{organisationId:props.user.userInfo.organisation},{headers:{token:props.user.user}})
        // .then(res=>{
        //     console.log(res)
        //     setData(res.data.result)
        // })
    },[])
    console.log(selectedDate)

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={5} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <form className="my-3 form">
            <h2 className="mb-3">FTD Report</h2>
            <div className="row m-auto">
            <section className="my-2 col-6">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={selectedDate}
                onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />} />
            </LocalizationProvider>
            </section>
            </div>
            <div className="my-3" style={{textAlign:"center"}}>
            <Button onClick={async ()=>{
                const response = await axios({
                url: `${process.env.REACT_APP_DEVELOPMENT}/api/user/group/ftd`,
                method: 'POST',
                responseType: 'blob',
                data:{dateGte:selectedDate,groupId:props.user.userInfo.groupId},
                headers:{token:props.user.user}
                });
                console.log(response)
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, `FTD_Report.xlsx`);

            }} variant="contained">Download Excel</Button>
            </div>
            </form>


            <form className="form">
            <h2 className="mb-3">MTD Report</h2>
            <div className="row m-auto">
            <section className="my-2 col-6">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={selectedDate}
                onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />} />
            </LocalizationProvider>
            </section>

            <section className="my-2 col-6">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="End Date"
                inputFormat="dd/MM/yyyy"
                value={selectedDate2}
                onChange={(newValue) => {
                    console.log(newValue);
                    setSelectedDate2(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />} />
            </LocalizationProvider>
            </section>
            </div>
            <div className="my-3" style={{textAlign:"center"}}>
            <Button onClick={async ()=>{
                const response = await axios({
                    url: `${process.env.REACT_APP_DEVELOPMENT}/api/user/group/mtd`,
                    method: 'POST',
                    responseType: 'blob',
                    data:{dateGte:selectedDate,dateLte:selectedDate2,groupId:props.user.userInfo.groupId},
                    headers:{token:props.user.user}
                    });
                    console.log(response)
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver.saveAs(blob, 'MTD_Report.xlsx');

            }} variant="contained">Download Excel</Button>
            </div>
            </form>


             </div>
    </div>
    )
}


const mapStateToProps =({EventUser})=>{
    return {
      user:EventUser
    }
  }

export default connect(mapStateToProps)(Report)

import React from 'react'
import "./Dashhead.scss"
import {withRouter} from 'react-router'
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import {connect} from 'react-redux'
import {Button} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import {deleteUser} from './redux/user/userActions'
const Dashhead = (props) => {
    console.log(props);
    let {id,display} = props
    return (
        <div className={display?"shadow-lg dashhead":'dashhead displayhidden'}>
            <h1>GP Admin</h1>
            {id===1?<div onClick={()=>props.history.push('dashboard')} className="menu-container-active">
                <p><HomeIcon /> <span className="pl-1">Home</span></p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('dashboard')} >
            <p><HomeOutlinedIcon /> <span className="pl-2">Home</span></p>
            </div>
            }

            {id===2?<div className="menu-container-active" onClick={()=>props.history.push('merchants')}>
                <p><NoteAddIcon /> <span className="pl-1">Merchants</span></p>
            </div>:
            <div className="menu-container menu-container-disabled" onClick={()=>props.history.push('merchants')}>
            <p><NoteAddOutlinedIcon /> <span className="pl-2">Merchants</span></p>
            </div>
            }

            {/* {id===3?<div className="menu-container-active" onClick={()=>props.history.push('organization')} >
                <p><EventAvailableIcon /> <span className="pl-1">Organization</span></p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('organization')} >
            <p><EventAvailableOutlinedIcon /> <span className="pl-2">Organization</span></p>
            </div>
            } */}

            {id===4?<div className="menu-container-active" onClick={()=>props.history.push('orders')} >
                <p><ReceiptLongIcon /> <span className="pl-1">Orders</span></p>
            </div>:
            <div className="menu-container menu-container-disabled" onClick={()=>props.history.push('orders')} >
            <p><ReceiptLongOutlinedIcon /> <span className="pl-2">Orders</span></p>
            </div>
            }

{/* {id===5?<div className="menu-container-active" onClick={()=>props.history.push('report')} >
                <p><ReceiptLongIcon /> <span className="pl-1">Reports</span></p>
            </div>:
            <div className="menu-container menu-container-disabled" onClick={()=>props.history.push('report')} >
            <p><ReceiptLongOutlinedIcon /> <span className="pl-2">Reports</span></p>
            </div>
            } */}

{id===6?<div className="menu-container-active" onClick={()=>props.history.push('reportNew')} >
                <p><ReceiptLongIcon /> <span className="pl-1">Report (New)</span></p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('reportNew')} >
            <p><ReceiptLongOutlinedIcon /> <span className="pl-2">Report (new)</span></p>
            </div>
            }

<div onClick={()=>{
                props.deleteUser();
                props.history.push("/")
            }} className="mt-3" style={{textAlign:"center"}}>
                <Button startIcon={<LogoutIcon />} variant="contained" color="secondary">Logout</Button>
            </div>
        </div>
    );
}

const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        deleteUser:()=>dispatch(deleteUser())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Dashhead));